<template>
  <div class="container my-5">
    <div class="row justify-content-center mt-5" v-if="isLoading">
      <Spinner />
    </div>
    <div class="row" v-else>
      <div class="col-lg-5 col-md-6 col-12">
        <InfoTarotista />
        <Cursos />
      </div>
      <div class="col-lg-7 col-md-6 col-12 mt-3 mt-md-0">
<!--        <Calendario />-->
        <EventosAgendados/>
      </div>
      <div class="col-lg-4 col-12"></div>
      <div class="col-12 mt-3">
        <Historial :latestLectures="latestLectures" />
      </div>
    </div>
    <ModalCalendly/>
    <ModalCalendlyEvents/>
    <div v-if='url !== null'>
      <ModalLlamadaAgendada :url='url'/>
    </div>
    <ModalAvisoBienvenido/>
  </div>
</template>

<script>
  import InfoTarotista from './InfoTarotista';
  import Cursos from './Cursos';
  //import Calendario from './Calendario';
  import EventosAgendados from './EventosAgendados.vue';
  import Historial from './Historial';
  import Spinner from '../loadingspinner/Spinner.vue';
  import axiosClient from '../../config/axiosClient';
  import { enableLogs } from '../../config';
  import ModalCalendly from "@/components/DashboardTarotista/Modal/ModalCalendly.vue";
  import ModalCalendlyEvents from "@/components/DashboardTarotista/Modal/ModalCalendlyEvents.vue";
  import ModalLlamadaAgendada from '@/components/util/ModalLlamadaAgendada.vue';
  import ModalAvisoBienvenido from './Modal/ModalAvisoBienvenido.vue';

  export default {
    name: 'DashboardTarotista',
    data() {
      return {
        estadoInvitacion: "",
        type: "",
        latestlectures: [],
        isLoading: true,
        canal: "",
        url: null,
      };
    },
    components: {
      ModalLlamadaAgendada,
      InfoTarotista,
      Cursos,
     // Calendario,
      Historial,
      Spinner,
      ModalCalendly,
      ModalCalendlyEvents,
      EventosAgendados,
      ModalAvisoBienvenido,
    },
    mounted() {
      window.addEventListener("load", () => {
        // Si el usuario está autenticado
        if (this.$store.state.isLoggedIn) {
          // Comprobar si el modal ya fue mostrado en esta pestaña
          if (!sessionStorage.getItem("modalShown")) {
            // Mostrar el modal
            this.showModalAvisoBienvenido();
            // Registrar que el modal fue mostrado en esta pestaña
            sessionStorage.setItem("modalShown", "true");
          }
        }
      });
      
      if(!this.$store.state.hasRefreshToken) {
        this.$router.push('/')
      }
      this.getActivo();
      this.getEnvioNotificacion();
    },

    methods: {
      showModalAvisoBienvenido(){
        this.$bvModal.show('modalAvisoBienvenido');
      },
      showModal(){
        this.$bvModal.show('modalCalendly');
      },
      showModalEvents(){
        this.$bvModal.show('modalCalendlyEvents');
      },
      async getLatestLectures() {
        try {
          const lectures = await axiosClient.get(
            '/tarot-reader/get-latest-lectures',
            {
              headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
            }
          );
          if(enableLogs) console.log(lectures.data.latestlectures);
          this.latestLectures = lectures.data.latestlectures;
          this.isLoading = false;
         if(enableLogs) console.log(this.latestLectures);
         if(enableLogs) console.log('successfully fetched tarot reader latest lectures');
        } catch (error) {
         if(enableLogs) console.log('could not get latest tarot reader lectures');
         if(enableLogs) console.log(error);
          this.isLoading = false;
        }
      },
      async getActivo(){
        const valorActivo = await axiosClient.get(
            '/tarot-calendly/get-activo',
            {
              headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
            }
        );
        if (valorActivo.data.estadoInvitacion === 0){
          this.getOrganizationInvitation();
        }else if (valorActivo.data.estadoInvitacion === 1){
         await this.getEventType();
        }
      },
      async getEventType(){
        const response = await axiosClient.get(
            '/tarot-calendly/get-event-type',
            {
              headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
            }
        );
        if (response.data.eventType === 0) {
          this.showModalEvents();
          await this.getEventType();
        }
      },
      async getOrganizationInvitation() {
        if (this.estadoInvitacion !== 'accepted') {
          try {
            const invitations = await axiosClient.get(
                '/tarot-calendly/get-organization-invitation',
                {
                  headers: {Authorization: `Bearer ${this.$store.state.jwt}`},
                }
            );
            this.estadoInvitacion = invitations.data.estadoInvitacion;
            this.type = invitations.data.type;
            if (this.estadoInvitacion === 'pending' && this.type === 'agendada') {
              this.showModal();
              this.getActivo();
            }else {
              // Refrescar la página
              window.location.reload();
            }
          } catch (error) {
            if (enableLogs) console.log('could not get latest tarot reader lectures');
            if (enableLogs) console.log(error);
            this.isLoading = false;
          }
        }
      },
      async getEnvioNotificacion(){
        const envio = await axiosClient.post(
          '/tarot-calendly/post-notificacion/envio',
          {},
          {
            headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
          }
        );
        if (envio.data.roomID !== null){
          this.canal = envio.data.canal;
          this.url = this.getNextLink(envio.data.roomID);
        }
      },
      getNextLink(roomId) {
        let nextLink = ``;
        switch (this.canal) {
          case 'VIDEO':
            nextLink = `/sala-espera?roomId=${roomId}`;
            break;
          case 'CHAT':
            nextLink = `/chat?roomId=${roomId}`;
            break;
          case 'VOZ':
            nextLink = `/room-llamada?roomId=${roomId}`;
            break;
        }
        return nextLink;
      },
    },
    created() {
      this.getLatestLectures();
    },
  };
</script>
