<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 images">
                <div class="row" style="height: 100%">
                    <div class="col m-auto" style="height: 100%">
                        <div class="content-user">
                            <img src="../../assets/img/logo.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="controles">
                <div class="row">
                    <div
                        class="
                            col-lg col-md-12
                            time
                            align-self-end
                            mt-lg-0 mt-3
                        "
                    >
                        <div v-if="false" class="icon-setting">
                            <DropdownSetting />
                        </div>
                        <span>{{ dosDigitos(minutosMostrar) }}</span
                        >:<span>{{ dosDigitos(segundosMostrar) }}</span>
                    </div>
                    <div class="col-lg col-md-12 m-auto mb-lg-0 mb-3">
                        <div class="text-center">
                            <button
                                :class="claseVoice"
                                v-on:click="toggleVoice()"
                            >
                                <i
                                    :class="IconVoice"
                                    class="fas fa-microphone"
                                ></i>
                            </button>
                            <button
                                :disabled="!this.connected"
                                v-on:click="disconnectDevice()"
                                class="btn-cancel"
                                v-b-modal.modal-llamada-fin
                            >
                                <i class="fas fa-power-off"></i>
                            </button>
                            <ModalFinLlamada :endCall="endCall" />
                        </div>
                    </div>
                    <div class="col-lg col-md-12"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DropdownSetting from './DropdownSetting.vue'
import ModalFinLlamada from './modal/ModalFinLlamada.vue'
import { Device } from 'twilio-client'
import { enableLogs } from '../../config';
//const { Kushki } = require('@kushki/js')


export default {
    data() {
        return {
            // allowed media
            voiceAllowed: false,
            // enabled media
            voiceEnabled: true,
            // Remote participant phone number
            phoneNum: null,
            // Room Id
            roomId: null,
            // Twilio voice token
            twilioToken: null,
            // Twilio device
            twilioDevice: null,
            // Flag if the twilio device is ready
            deviceReady: false,
            // Flag if the twilio device is connected
            connected: false,
            // Object that represents the outgoing connection
            outgoingConnection: null,
            //Flag to show modal indicating the call has ended
            endCall: false,
            // Flag to see if the user is authorized
            userAuthorized: false,
            segundos: 0,
            minutos: 0,
            tiempo: null,

          tiempoMostrar: null,
          segundosMostrar: 0,
          minutosMostrar: 0,
        }
    },
    created() {
        this.roomId = this.$route.query.roomId
        if (this.socket) {
            this.authorizeUser()
            this.getVoiceToken()
            this.getParticipantNumber()
            1
        }
        this.checkVoicePermissions()
    },
    computed: {
        socket() {
            return this.$store.state.socket
        },
        claseVoice() {
            return this.voiceEnabled ? 'btn-voice' : 'btn-voice active'
        },
        IconVoice() {
            return this.voiceEnabled
                ? 'fas fa-microphone'
                : 'fas fa-microphone-slash active'
        },
        pageReady() {
            return (
                this.phoneNum != null && this.deviceReady && this.userAuthorized
            )
        },
    },
    watch: {
        pageReady: async function (ready) {
            if (ready) {
               console.log('ingresamos proceso llaada');
                try {
                    const outgoingConnection = await this.twilioDevice.connect({
                        To: this.phoneNum,
                    })
                   console.log('pasamos twilio connet');
                    const sendSid = this.sendSid
                    outgoingConnection.on('ringing', function () {
                       if(enableLogs) console.log('Ringing...')
                        sendSid()
                    })
                   console.log('pasanos conection ringing 2');
                    const cronometro = this.cronometro

                    const socket = this.socket
                    socket.on('initControl',(timeObject)=>{
                      console.log(JSON.stringify(timeObject));
                      // Extraer las propiedades de tiempo del objeto
                      const inicio = new Date(timeObject.inicio); // Convertir a objeto Date
                      const fin = new Date(timeObject.fin); // Convertir a objeto Date

                      // Calcular la duración en minutos
                      const duracion = (fin - inicio) / 1000 / 60; // Convertir a minutos

                      console.log(`Duración total de la llamada: ${duracion} minutos`);
                      this.minutosMostrar = duracion;
                      this.cronometroMostrar();
                    });
                    outgoingConnection.on('accept', function () {
                        socket.emit('callinit',true);
                        const dataLog = {
                            message: "¡La videollamada ha iniciado!",
                            roomId: this.roomId,
                            cambRedis: false,
                        };
                        this.$store.commit("backLog", dataLog);
                       if(enableLogs) console.log('Call accepted!')
                        cronometro();
                    })
                    socket.on('prox-finish', () => {
                        const dataLog = {
                            message: "¡La videollamada esta por finalizar! quedan 2 minutos",
                            roomId: this.roomId,
                            cambRedis: true,
                        };
                        this.$store.commit("backLog", dataLog);
                      this.$store.commit("setMessage", "¡El chat esta por finalizar! quedan 2 minutos");
                    });
                    socket.on('end-room', () => {
                      console.log('********** end-room *********');
                      const dataLog = {
                        message: "¡La videollamada ha finalizado!",
                        roomId: this.roomId,
                        cambRedis: true,
                     };
                      this.$store.commit("backLog", dataLog);
                      socket.emit('end-room',true);
                      outgoingConnection.disconnect();
                      this.clearEventsSocket();
                      this.detenerCronometroMostrar();
                      this.connected = false;
                      if (
                          this.$store.state.tokenData &&
                          this.$store.state.tokenData.permissions.isTarotReader
                      )
                        this.socket.emit("change-state", "AVAILABLE");
                      this.$router.push("/");
                    });
                    this.connected = true
                    await this.$nextTick()
                } catch (err) {
                   if(enableLogs) console.log(err)
                    this.connected = false
                }
            }
        },
        twilioToken: function (token) {
            if (token) {
               if(enableLogs) console.log('Creating twilio device')
                let device = new Device()
                const setDeviceReady = () => {
                    this.deviceReady = true
                }
                device.on('ready', () => {
                    // The device is now ready
                   if(enableLogs) console.log('Twilio.Device is now ready for connections')
                    setDeviceReady()
                })
                device.on("disconnect", () => {
                    this.socket.emit("change-state", "AVAILABLE");
                    const dataLog = {
                        message: "¡La videollamada ha finalizado!",
                        roomId: this.roomId,
                        cambRedis: true,
                     };
                      this.$store.commit("backLog", dataLog);
                    this.socket.emit('end-room',true);
                    this.clearEventsSocket();
                    this.detenerCronometroMostrar();
                    //this.endCall = true;
                    this.$store.commit("setMessage", "¡La llamada ha finalizado!");
                    //this.end = true;
                    this.$router.push("/");
                });
                device.setup(token, {
                    codecPreferences: ['opus', 'pcmu'],
                    fakeLocalDTMF: true,
                    enableRingingState: true,
                    debug: true,
                })
                this.twilioDevice = device
            }
        },
        socket: function (sckt) {
            if (sckt) {
                this.authorizeUser()
                this.getVoiceToken()
                this.getParticipantNumber()
            }
        },
    },
    methods: {
      clearEventsSocket() {
        delete this.socket._callbacks['$initControl'];
        delete this.socket._callbacks['$clock'];
      },
        authorizeUser() {
            const authorizeUser = () => (this.userAuthorized = true)
            const goHome = () => this.$router.push('/')
            this.socket.emit('start-voice-room', this.roomId, (response) => {
                if (response.code == 2) {
                    authorizeUser()
                }
                if (response.code == 1) {
                    goHome()
                }
            })
        },
        sendSid() {
           if(enableLogs) console.log('sending sid!')
            if (this.outgoingConnection)
                this.socket.emit(
                    'update-sid',
                    this.roomId,
                    this.outgoingConnection.parameters.CallSid
                )
        },
        async checkVoicePermissions() {
            try {
                this.voiceAllowed = true
                let stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                })
                stream.getTracks().forEach(function (track) {
                    track.stop()
                })
               if(enableLogs) console.log('Successfully allowed voice track')
                return true
            } catch (err) {
                this.voiceAllowed = false
                alert('Debes darle permiso de microfono al navegador!')
               if(enableLogs) console.log(err)
               if(enableLogs) console.log('Error allowing voice track')
                return false
            }
        },
        disconnectDevice() {
            console.log("Disconnecting device from call");
            //this.endCall = true;
            //console.log(this.endCall);
            this.socket.emit('end-room',true);
            this.outgoingConnection.disconnect();
            this.connected = false;
            if (
                this.$store.state.tokenData &&
                this.$store.state.tokenData.permissions.isTarotReader
            )
            this.socket.emit("change-state", "AVAILABLE");
        },
        getParticipantNumber() {
           if(enableLogs) console.log('Fetching remote participant phone number')
            this.socket.emit('get-participant-number', this.roomId, (num) => {
               if(enableLogs) console.log(
                    'Remote phone number found: ',
                    num.substring(0, 5),
                    '...'
                )
                this.phoneNum = num
                const dataLog = {
                    message: "Obteniendo numero de telefono del participante " + this.phoneNum,
                    roomId: this.roomId,
                    cambRedis: false,
                };
                this.$store.commit("backLog", dataLog);
            })
        },
        async getVoiceToken() {
          if(enableLogs) console.log('Fetching twilio voice token')
            this.socket.emit('get-token', this.roomId, (token) => {
               if(enableLogs) console.log('Twilio token: ', token)
                this.twilioToken = token
            })
        },
        // Toggle voice
        async toggleVoice() {
            if (this.voiceEnabled == false) {
                if (!(await this.checkVoicePermissions()))
                    return alert('Tienes que habilitar el acceso a la camara!')
                await this.enableVoice()
                // Checks if permissions are set
            } else {
                await this.disableVoice()
               if(enableLogs) console.log('Voice disabled')
            }
        },
        async enableVoice() {
           if(enableLogs) console.log('Enabled voice')
            this.outgoingConnection.mute(false)
            this.voiceEnabled = true
        },
        async disableVoice() {
           if(enableLogs) console.log('Disabled voice')
            this.outgoingConnection.mute(true)
            this.voiceEnabled = false
        },
        cronometro() {
          this.cronometroMostrar();
            if (this.tiempo == null) {
                this.tiempo = window.setInterval(this.intervalo, 1000)
            }
        },
        intervalo() {
            this.segundos++
            if (this.segundos == 60) {
                this.segundos = 0
                this.minutos++
                if (this.minutos == 60) {
                    this.minutos = 0
                }
            }
        },
      // Conteo descendente
      cronometroMostrar() {
        if (this.tiempoMostrar == null) {
          this.tiempoMostrar = window.setInterval(this.intervaloMostrar, 1000);
        }
      },
      intervaloMostrar() {
        if (this.segundosMostrar > 0 || this.minutosMostrar > 0) {
          if (this.segundosMostrar === 0) {
            if (this.minutosMostrar > 0) {
              this.segundosMostrar = 59;
              this.minutosMostrar--;
            }
          } else {
            this.segundosMostrar--;
          }
        }
      },
        dosDigitos(num) {
            var res = num.toString()
            if (res.length == 1) {
                res = '0' + res
            }
            return res
        },
      detenerCronometroMostrar() {
        if (this.tiempoMostrar !== null) {
          clearInterval(this.tiempoMostrar);
          this.tiempoMostrar = null;
        }
      },
    },
    components: {
        DropdownSetting,
        ModalFinLlamada,
    },
}
</script>

<style scoped>
.no-media-div {
    width: 640px;
    height: 480px;
    background-color: black;
    display: flex;
}

.images {
    height: calc(100vh - 100px);
    background: rgb(59, 59, 59);
}
.content-user {
    width: 80%;
    height: 80%;
    background: rgb(39, 39, 39);
    margin: auto;
    margin-top: 5%;
    position: relative;
}

.content-user img {
    max-width: 35%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.controles {
    min-height: 100px;
    background: #4b4b4b;
}

button {
    margin: 0 10px;
}
button.btn-voice,
.btn-camera,
.btn-cancel {
    height: 40px;
    width: 40px;
    display: inline-flex;
    margin-top: 28px;
    border: 1px solid #dadce0;
    border-radius: 50%;
    transition-property: all;
    transition-duration: 0.8s;
    background-color: #ffffff;
}
button.btn-voice:hover,
.btn-camera:hover,
.btn-cancel:hover {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 5px 4px rgba(209, 209, 209, 1);
    -moz-box-shadow: 0px 0px 5px 4px rgba(209, 209, 209, 1);
    box-shadow: 0px 0px 5px 4px rgba(209, 209, 209, 1);
}
button.btn-voice i,
.btn-camera i,
.btn-cancel i {
    margin: auto;
    color: #707070;
}

button.btn-voice.active,
.btn-camera.active {
    background-color: #d93025;
}
button.btn-voice.active i,
.btn-camera.active i {
    color: #f5f5f5;
}

.btn-container {
    left: 50%;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    padding: 8px 0;
    position: absolute;
    top: 5%;
    z-index: 99 !important;
}

.icon-mute {
    height: 28px;
    width: 28px;
    background-color: red;
    border-radius: 50%;
    padding: 3px;
}

.icon-mute i {
    width: 5px;
    height: 5px;
    color: #fff;
}
.icon-setting {
    margin-top: 28px;
    position: relative;
}

.btn-setting:hover {
    color: #707070;
}

.panel-setting {
    position: absolute;
}

.time,
.time span {
    font-family: maven-medium;
    color: #fff;
}
.time span {
    font-family: maven-medium;
    color: #fff;
}
</style>
