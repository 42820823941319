<template>
  <div>
    <b-dropdown no-caret right>
      <template #button-content>
        <i class="fas fa-user"></i>
        <span class="name-user">{{
          tokenData != null ? tokenData.personName + '👋': null
        }}</span>
      </template>
      <b-dropdown-group id="dropdown-group-1" style="">
        <b-dropdown-item-button
          class="my-4 content-focus"
          v-b-modal.modalPerfil
        >
          <button class="btn btn-user">
            <i class="fas fa-user"></i>
          </button>
          Perfil
          <ModalPerfil />
        </b-dropdown-item-button>

        <b-dropdown-item-button class="my-4" v-b-modal.modalPass>
          <button class="btn btn-user">
            <i class="fas fa-cog"></i>
          </button>
          Contraseña
          <ModalPass />
        </b-dropdown-item-button>

        <!-- <b-dropdown-item-button class="my-4">
          <button class="btn btn-user">
            <i class="fas fa-newspaper"></i>
          </button>
          Mi actividad
        </b-dropdown-item-button> -->
      </b-dropdown-group>

      <div class="my-4 mt-4 container-btn">
        <button @click="logout()" class="btn btn-sm btn-loguot">
          Cerrar Sesión
        </button>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
  import ModalPerfil from './modal/ModalPerfil.vue';
  import ModalPass from './modal/ModalPass.vue';
  import axiosClient from '../../config/axiosClient';

  export default {
    components: {
      ModalPerfil,
      ModalPass,
    },
    computed: {
      tokenData() {
        return this.$store.state.tokenData;
      },
    },
    methods: {
      async logout() {
        this.$store.state.socket.emit('hide', {
            value: 0,
            chanels: {
              chat: false,
              video: false,
              voice: false
            }
          });
        // Petitcion para borrar el cookie http-only
        await axiosClient.post('/users/logout');
        window.localStorage.removeItem('info');
        window.sessionStorage.removeItem('modalShown');
        this.$store.commit('logout');
        window.location.href = '/?logout';
      },
    },
  };
</script>
<style scoped>
@media (max-width:768px) {
  .icons .name-user {
    margin-left: .5em;
    font-size: .875rem;
  }
  .icons .user {
    margin-right: .5rem;
  }
  .icons .btn {
    display: flex;
    align-items: center;
    height: auto;
  }
}
  a {
    text-decoration: none;
  }
  /deep/ .dropdown-menu {
    width: 260px;
  }
  /deep/ .btn-secondary {
    background: transparent;
    border: none;
    padding: 3px;
  }
  /deep/ .show > .btn-secondary.dropdown-toggle {
    background: #5016826c;
    border: none;
    padding: 3px;
  }
  .name-user {
    margin-left: 10px;
    float: right;
  }

  .btn-user {
    border: 1px solid #501682;
    margin-right: 10px;
  }

  .btn-user i {
    color: #501682;
    margin: auto;
    max-width: 20px;
  }
  .btn-loguot {
    background: #ffc109b7;
    color: #501682;
    padding: 8px 20px;
    font-family: maven-semibold;
  }
  .btn-loguot:hover {
    background: #dba501;
    color: #650bbf;
  }
  /deep/.container-btn {
    background: transparent;
    padding-left: 15px;
    padding-right: 15px;
  }
  /deep/.dropdown-item:active {
    background: #e9ecef;
    color: #333;
  }
</style>
