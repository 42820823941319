<template>
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      id="modalAvisoBienvenido"
      centered
      hide-footer
      hide-header-close
    >
      <p class="mt-4 mb-4 text-modal">
        "Bienvenido de nuevo {{ tokenData ? tokenData.personName + ' 👋' : '' }}"
      </p>
      <b-button
        size="lg"
        class="col-12 btn-modal"
        @click="closeModal"
      >
        OK
      </b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      showModal: true,
    };
  },
  computed: {
    tokenData() {
      return this.$store.state.tokenData;
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modalAvisoBienvenido");
    },
  },
};
</script>

<style scoped>
/deep/ .center-flex {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente si el contenedor tiene altura específica */
  margin: 5px;
}
/deep/ .close {
  background: transparent;
  border: none;
}
/deep/ .modal-header {
  border-bottom: none;
}
/deep/ .modal-body {
  padding: 20px 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 1.5;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/ .modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/ .btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
  padding: 10px 0;
  font-size: 18px;
}
</style>
