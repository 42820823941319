<template>
  <!--Modal confirmacion pago-->
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      v-model="showModal"
      id="modal-llamada-confirmacion"
      size="lg"
      centered
    >
      <div class="row">
        <div class="col-12 mb-2">
          <p class="text-modal">
            Tienes una lectura{{getTypeRoom(channel)}} con {{ personName }}
          </p>
        </div>
        <div v-if="false" class="col-12 mb-2">
          <span class="text-sutitulo"
            >Selecciona la salida de microfono y video</span
          >
        </div>
        <div v-if="false" class="col-6 mb-2">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Audio</label>
            <select class="form-select" id="exampleFormControlSelect1">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div>
        <div v-if="false" class="col-6 mb-2">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Microfono</label>
            <select class="form-select" id="exampleFormControlSelect1">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div>
      </div>

      <template
        #modal-footer="{
          /*hide*/
        }"
      >
        <div class="row">
          <!--
          <div class="col-6 text-end">

            <b-button
              size="sm"
              class="btn-sm btn btn-line"
              v-on:click="confirmPayment"
            >
              No atender
            </b-button>

          </div>
          -->
          <div class="col-12 text-center">
            <b-button
              size="sm"
              class="btn-sm btn btn-yellow"
              v-on:click="goToCall"
            >
              Atender
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
import axiosClient from "@/config/axiosClient";
export default {
  watch: {
    triggerModal: async function (value) {
      if (value){
        const data = {
            message: "Se emite sonido de modal atender",
            roomId: this.roomId,
            cambRedis: true,
          };
        this.$store.commit("backLog", data);
        await this.getTextSms();
        this.showModal = true;
      }
      this.stopModal()
    },
  },
  mounted(){
  },
  props: {
    triggerModal: Boolean,
    personName: String,
    channel: String,
    roomId: String,
  },

  data: () => {
    return {
      showModal: false,
      timeOutModal: null,
      textoSms: '',
      userInfo_telefono: null,
      url: null,
      serviceId: null,
      clave: null,
      isProcessing: false, // Bandera para controlar doble ejecución
    };
  },
  computed: {
    socket: function() {
      return this.$store.state.socket;
    },
    jwt: function() {
      return this.$store.state.jwt;
    },
  },
  methods: {
    async datosUser() {
      try {
        const datos = await axiosClient.get(
            '/users/get-users/datos',
            {
              headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
            }
        );
        this.userInfo_telefono = datos.data.telefono;
        console.log('Teléfono del usuario obtenido:', this.userInfo_telefono);
        await this.enviarSms();
      } catch (error) {
        console.error('Error during registroUse:', error);
      }
    },
    async getTextSms() {
      try {
        const response = await axiosClient.get('/datos-env/get-data-sms', {
          headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
        });

        this.textoSms = response.data.textSms;
        this.url = response.data.url;
        this.serviceId = response.data.serviceId;
        this.clave = response.data.clave;

        if (!this.url || !this.serviceId || !this.clave) {
          throw new Error('Faltan parámetros en la respuesta del backend.');
        }

        console.log('Datos SMS cargados:', response.data);
        await this.datosUser();
      } catch (error) {
        console.error('Error al obtener datos del SMS:', error.message);
      }
    },
    async enviarSms() {
      try {
        // Configuración del SMS a enviar
        const payload = new URLSearchParams();
        payload.append('service_id', this.serviceId);
        payload.append('clave', this.clave);
        payload.append('nro_movil', this.userInfo_telefono);
        payload.append('nro_corto', '3030'); // Valor fijo
        payload.append('texto', this.textoSms);
        payload.append('tasacion', '0');

        if (!/^569\d{8}$/.test(this.userInfo_telefono)) {
          console.error('El número de teléfono es inválido. Debe comenzar con "569" y tener 11 dígitos.');
          return;
        }

        // Realizar la petición POST
        const response = await axios.post(this.url, payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        console.log('SMS Enviado con éxito:', response.data);
      } catch (error) {
        console.error('Error al enviar SMS:', error.message);
      }
    },
    async stopModal() {
    if (this.isProcessing) {
      return; // Si ya está en proceso, no hacer nada
    }

    this.isProcessing = true; // Activa la bandera
    var m = this;
    window.inactivoCall = true;

    this.timeOutModal = window.setTimeout(async function () {
      if (window.inactivoCall) {
        try {
          const usado = await axiosClient.post(
            '/payment/pep-return',
            { roomId: m.roomId },
            { headers: { Authorization: `Bearer ${m.$store.state.jwt}` } }
          );
          console.log('Se ha registrado el uso de la llamada:', usado.data);
        } catch (error) {
          console.error('Error durante el registro de la llamada:', error);
        }

        window.document.getElementById('audioLlamada').pause();
        m.showModal = false;
        m.$store.commit('disableNotPresentFun', true);
      }
      m.isProcessing = false; // Libera la bandera
    }, 180000);
  },
    goToCall() {
      window.inactivoCall = false;
      window.clearTimeout(this.timeOutModal)
      window.document.getElementById('audioLlamada').pause();
      let url;
      const data = {
            message: "El tarotista ha atendido la llamada",
            roomId: this.roomId,
            cambRedis: true,
          };
      this.$store.commit("backLog", data);
      this.socket.emit("change-state", 'OCCUPIED');
      switch (this.channel) {
        case "CHAT":
          url = `chat?roomId=${this.roomId}`;
          break;
        case "VIDEO":
          url = `sala-espera?roomId=${this.roomId}`;
          break;
        case "VOICE":
          url = `room-llamada?roomId=${this.roomId}`;
          break;
      }
      this.$router.push(url);
      this.hideModal();
    },
    getTypeRoom(type){
      switch (type) {
        case 'VOICE':
          return ' de Llamada';
        case 'VIDEO':
          return ' de Video';
        case 'CHAT':
          return ' de Chat';
        default:
          return '';
      }
    },
    hideModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
 /deep/.close {
    background: transparent;
    border: none;
    display: none;
  }
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
  font-family: maven-regular;
}
.text-modal {
  color: #333;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
  display: block;
}
/deep/.btn-yellow {
  background-color: #ffc209;
  color: #501682;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
  padding: 6px 20px;
}
.btn-yellow:hover {
  background: #dba501 !important;
  color: #501682 !important;
  box-shadow: #501682;
}
/deep/.btn-line {
  border: 1px solid #501682;
  background-color: transparent;
  color: #501682;
  font-family: maven-bold;
  text-transform: uppercase;
  padding: 6px 20px;
}

.text-sutitulo {
  font-size: 13px;
}
</style>
