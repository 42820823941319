<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 images">
                <div class="row" style="height: 100%">
                    <div class="col-12 content-img">
                      <div class="image">
                        <img src="../../assets/img/logo.png" alt="" />
                      </div>
                        
                    </div>
                    <div class="container-video mx-auto mt-4">
                        <div
                            v-if="localParticipant !== null /*&& videoEnabled*/"
                            id="local-media-div"
                            class="text-center"
                        >
                            <div
                                class="contrainer-icon-microphone-mute"
                                v-if="!voiceEnabled"
                            >
                                <i class="fas fa-microphone-slash active"></i>
                            </div>

                            <div class="content-img-perfil" id="img-avatar">
                                <img id="avatar-propio" src="../../assets/img/avatar.jpg" alt="" />
                            </div>
                        </div>
                        <!--<div
              v-if="localParticipant === null || !videoEnabled"
              class="no-media-div"
            >
              <div class="contrainer-icon-video-mute">
                <i :class="claseIcon" class="fas fa-video"></i>
              </div>
              <div class="contrainer-icon-microphone-mute">
                <i class="fas fa-microphone-slash active"></i>
              </div>
            </div>-->
                    </div>
                    <div class="container-video mx-auto mt-4">
                        <div
                            v-if="remoteParticipant !== null"
                            id="remote-media-div"
                            class="text-center"
                        >
                            <div
                                class="contrainer-icon-microphone-mute-remote"
                                v-if="remoteAudioMuted"
                            >
                                <i class="fas fa-microphone-slash active"></i>
                            </div>
                            <div class="content-img-perfil">
                                <img id="avatar-invitado" src="../../assets/img/avatar.jpg" alt="" />
                            </div>
                        </div>
                        <!-- {{ remoteAudioMuted ? "remote audio muted" : null }}
            {{ remoteVideoMuted ? "remote video muted" : null }} -->
                    </div>
                </div>
            </div>

            <div class="controles">
                <div class="row">
                    <div class="col-lg-2 col-md-3 col-12 time align-self-end">
                        <span>{{ dosDigitos(minutosMostrar) }}</span
                        >:<span>{{ dosDigitos(segundosMostrar) }}</span>
                    </div>

                    <div class="col-8 col-md-6 col-12 m-auto">
                        <div class="text-center">
                            <button :class="claseVoice" @click="voice">
                                <i
                                    :class="IconVoice"
                                    class="fas fa-microphone"
                                ></i>
                            </button>
                            <button
                                @click="disconnectFromRoom"
                                class="btn-cancel"
                            >
                                <i class="fas fa-power-off"></i>
                            </button>

                            <button :class="claseCamera" @click="camera">
                                <i :class="claseIcon" class="fas fa-video"></i>
                            </button>
                        </div>
                    </div>

                    <div class="col-lg-2 col-3 col-12"></div>
                </div>
                <ModalFinVideo :endVideo="endVideo" />
            </div>
        </div>
    </div>
</template>

<script>
import ModalFinVideo from "./modals/ModalFinLlamada.vue"
import { enableLogs, celcomKushkiMerchantId, isProduction } from '../../config';
const { Kushki } = require('@kushki/js')


const { connect } = require("twilio-video");

export default {
  data() {
    return {
      // socket joined
      socketJoined: false,
      // twilio room
      twilioRoom: null,
      // allowed media
      videoAllowed: false,
      voiceAllowed: false,
      // enabled media
      videoEnabled: false,
      voiceEnabled: false,
      // twilio participants
      localParticipant: null,
      remoteParticipant: null,
      // remote tracks
      remoteVideoTrack: null,
      remoteVoiceTrack: null,
      remoteVideoMuted: false,
      remoteAudioMuted: false,
      // query params
      roomId: null,
      twilioToken: null,

      // Flag for video stream already appended
      videoAppended: false,

      segundos: 0,
      minutos: 0,
      tiempo: null,
      duracionTotal: '-',

      closeRoom: false,

      endVideo:false,

      tiempoMostrar: null,
      segundosMostrar: 0,
      minutosMostrar: 0,
    };
  },
  components: {
    ModalFinVideo,
  },
  async beforeDestroy() {
    console.info("@@@@@@@@@@@@@@@@@@@");
    if(enableLogs) console.log("Destroying twilio devices");
   if(enableLogs) console.log(this.twilioRoom);
    if (this.twilioRoom) {
      this.twilioRoom.localParticipant.tracks.forEach((publication) => {
        publication.track.stop();
        const attachedElements = publication.track.detach();
       if(enableLogs) console.log("unsubscribed from: " + publication.track);
        attachedElements.forEach((element) => element.remove());
      });
    }

    try {
      let stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
    } catch {
     if(enableLogs) console.log("");
    }
    try {
      let stream = await navigator.mediaDevices.getUserMedia({
        voice: true,
      });
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
    } catch {
     if(enableLogs) console.log("");
    }
  },
  created() {
    // Get query parameters and set them as states
    this.roomId = this.$route.query.roomId;

    // Make the socket listen start
    if (this.$store.state.socket) {
      this.startSocket();
    }
  },
  mounted: function () {
  },
  watch: {
    twilioToken: async function () {
      if (this.twilioToken == null) return;
      if (this.twilioToken == undefined) return;
      if (this.twilioToken == "") return;

      // Connect to the twilio room
     if(enableLogs) console.log("Connecting to twilio room: ", this.twilioToken);

      return await this.twilioConnect();
    },
    socket: async function (socket) {
      if (socket === null || socket === undefined) return;
      await this.startSocket();
    },
    socketJoined: async function () {
      this.getToken();
    },
  },
  computed: {
    socket() {
      return this.$store.state.socket;
    },
    claseVoice() {
      return this.voiceEnabled ? "btn-voice" : "btn-voice active";
    },
    IconVoice() {
      return this.voiceEnabled
        ? "fas fa-microphone"
        : "fas fa-microphone-slash active";
    },
    claseCamera() {
      return this.videoEnabled ? "btn-camera" : "btn-camera active";
    },
    claseIcon() {
      return this.videoEnabled ? "fas fa-video" : "fas fa-video-slash active";
    },
    Icon() {
      return this.videoEnabled ? "fas fa-video" : "fas fa-video-slash active";
    },
  },
  methods: {
    async getToken() {
     if(enableLogs) console.log("Asking the backend for the twilio token ");
      await this.$nextTick();
      let socket = this.$store.state.socket;
      if(enableLogs) console.log("getToken socket: ", socket);
     if(enableLogs) console.log("get-video-token:", this.roomId, this.$store.state);
      //let responseToken;
      socket.emit("get-video-token", this.roomId, (response) => {
       if(enableLogs) console.log("Got twilio token from backend");
       if(enableLogs) console.log(response);
        this.twilioToken = response.token;
      });
    },
    async twilioConnect() {
      // Wait for all the params to be set
      await this.$nextTick();

      // Get audio permissions
      let canSendVoice = await this.checkVoicePermissions();
      let canSendVideo = await this.checkVideoPermissions();

      // Connect to the room
     if(enableLogs) console.log(this.twilioToken);
      let room = await connect(this.twilioToken, {
        debug: true,

        name: this.roomId,
        audio: canSendVoice,
        video: canSendVideo,
      });
     if(enableLogs) console.log(room);

      // Show your video track

      this.localParticipant = room.localParticipant;

      this.enableVideo();
      this.enableVoice();

      await this.$nextTick();

     if(enableLogs) console.log(
        `Connected to the Room as LocalParticipant "${room.localParticipant.identity}"`
      );

      // Log any Participants already connected to the Room
      room.participants.forEach((participant) => {
       if(enableLogs) console.log(
          `Participant "${participant.identity}" is already connected to the Room`
        );
        this.handleParticipant(participant);
      });

      // Log new Participants as they connect to the Room
      room.on("participantConnected", (participant) => {
       if(enableLogs) console.log(
          `Remote participant "${participant.identity}" has connected to the Room`
        );
        this.handleParticipant(participant);
      });

      // Log Participants as they disconnect from the Room
      room.on("participantDisconnected", (participant) => {
       if(enableLogs) console.log(
          `Participant "${participant.identity}" has disconnected from the Room`
        );
        this.handleParticipantDisconnect(participant);
      });

      this.twilioRoom = room;
    },
    clearEventsSocket() {
      delete this.socket._callbacks['$initControl'];
      delete this.socket._callbacks['$lost-partener'];
      delete this.socket._callbacks['$reconect-partener'];
      delete this.socket._callbacks['$prox-finish'];
      delete this.socket._callbacks['$end-room'];
      delete this.socket._callbacks['$clock'];
      delete this.socket._callbacks['$waitpartener'];
    },
    async disconnectFromRoom() {
      await this.$nextTick();
      if (this.twilioRoom === null) return;
      this.twilioRoom.localParticipant.videoTracks.forEach((publication) => {
        publication.track.stop();
        publication.unpublish();
      });
      if (
        this.$store.state.tokenData &&
        this.$store.state.tokenData.permissions.isTarotReader
      )
        this.socket.emit("change-state", "AVAILABLE");
      this.twilioRoom.disconnect();
      this.socket.emit("end-room", this.roomId);
      this.endVideo = true;
      this.closeRoom = true;
    },
    async handleParticipant(participant) {
      participant.on("trackSubscribed", (track) => {
        document.getElementById("remote-media-div").appendChild(track.attach());
      });

      await this.$nextTick();

      participant.tracks.forEach((publication) => {
        publication.on("subscribed", this.handleRemoteMute);
        publication.on("subscribed", this.handleRemoteUnute);

        if (publication.isSubscribed) {
          const track = publication.track;
          this.handleRemoteMute(track);
          this.handleRemoteUnute(track);
          document
            .getElementById("remote-media-div")
            .appendChild(track.attach());
        }
      });

      this.remoteParticipant = participant;
    },
    async handleParticipantDisconnect(participant) {
      participant;
      this.remoteParticipant = null;
    },
    async setLocalTracks() {
      await this.$nextTick();

      if (
        !this.videoAllowed ||
        !this.videoEnabled ||
        this.localParticipant === null
      )
        return;

      if (this.videoAppended) return;

      // Add your local stream to the page
      this.localParticipant.videoTracks.forEach((publication) => {
        const element = publication.track.attach();
        document.getElementById("local-media-div").appendChild(element);
      });

      this.videoAppended = true;
    },

    async startSocket() {
      if(enableLogs) console.log("startSocket");
      let socket = this.$store.state.socket;
      if(enableLogs) console.log("startSocket socket: ", socket);
      /*
      const goToHome = () => {
        this.$router.push("/");
      };

      if (this.$store.state.tokenData.permissions.isTarotReader){
        socket.emit("join-video-room", {roomId: this.roomId, join: true}, (response) => {
        if (response.code == 1) goToHome();

        if(enableLogs) console.log(response);

          this.socketJoined = response.code === 2;
        });
      }else{
        this.socketJoined = true
      }*/
      this.socketJoined = true
      
      // Evento de sala terminada
      /*socket.on("room-ended", (data) => {
        if(enableLogs) console.log(data);
        this.socket.emit("change-state", "AVAILABLE");
        this.$store.commit('setMessage', '¡La Videollamada ha finalizado!')
        if(enableLogs) console.log("el modal es: " + this.endVideo)
        this.$router.push("/");
      });*/

      this.socket.on('initControl',(timeObject)=>{
        console.log(JSON.stringify(timeObject));
        // Extraer las propiedades de tiempo del objeto
        const inicio = new Date(timeObject.inicio); // Convertir a objeto Date
        const fin = new Date(timeObject.fin); // Convertir a objeto Date

        // Calcular la duración en minutos
        const duracion = (fin - inicio) / 1000 / 60; // Convertir a minutos

        console.log(`Duración total de la llamada: ${duracion} minutos`);
        this.minutosMostrar = duracion;
        this.cronometro();
        this.cronometroMostrar();
        //this.tarotConected = true;
      });
      this.socket.on(this.roomId+'-chargeToken',(idSusc)=>{
        let kushki = new Kushki({
            merchantId: celcomKushkiMerchantId,
            inTestEnvironment: !isProduction,
            regional: false,
        })

        kushki.requestTokenCharge({
            subscriptionId: idSusc
        }, async (response2) => {
            if(response2.token){
              this.socket.emit(this.roomId+'-chargeToken', response2.token);
            }
        });
      });
      this.socket.on('clock',(obj)=>{
        this.minutos = obj.minTransc;
        this.segundos = obj.segTransc;
        this.duracionTotal = obj.duracionTotal;
        console.log(this.duracionTotal);
        console.log(this.minutos);
        this.minutosMostrar = (this.duracionTotal/60) - (this.minutos + 1);
        this.segundosMostrar = 60 - this.segundos;
      })
      socket.on('lost-partener', () => {
        const dataLog = {
            message: "El otro participante esta teniendo problemas de conexion",
            roomId: this.roomId,
            cambRedis: true,
          };
          this.$store.commit("backLog", dataLog);
        this.$store.commit("setMessage", 'El otro participante esta teniendo problemas de conexión')
      });
      socket.on('reconect-partener', () => {
        const dataLog = {
            message: "El otro participante se reconecto",
            roomId: this.roomId,
            cambRedis: true,
          };
          this.$store.commit("backLog", dataLog);
        this.$store.commit("setMessage", 'El otro participante se reconecto');
      });
      socket.on('prox-finish', () => {
        const dataLog = {
            message: "¡La videollamada esta por finalizar! quedan 2 minutos",
            roomId: this.roomId,
            cambRedis: true,
          };
          this.$store.commit("backLog", dataLog);
        this.$store.commit("setMessage", "¡La videollamada esta por finalizar! quedan 2 minutos");
      });
      socket.on('end-room', (manual) => {
        if(this.$store.state.tokenData) 
        if(this.$store.state.tokenData.permissions)
        if (this.$store.state.tokenData.permissions.isTarotReader) {
          this.socket.emit("change-state", "AVAILABLE");
        }
        this.clearEventsSocket();
        this.$store.commit("showModal", true);
        const data = {
        message: !this.closeRoom 
          ? "El otro participante corto la sala" 
          : this.closeRoom 
            ? "Finalizaste la sala" 
            : "¡El chat ha finalizado!",
        roomId: this.roomId,
        cambRedis: true,
        };
        this.$store.commit("backLog", data);
        this.$store.commit("setMessage", manual ? (!this.closeRoom ? "El otro participante corto la sala":'Finalizaste la sala') : "¡La videollamada ha finalizado!");
        this.end = true;
        this.$router.push("/");
      });
    },
    async handleRemoteMute(track) {
      track.on("disabled", () => {
        if(enableLogs) console.log("Remote track disabled");
        if (track.kind === "audio") {
          this.removeRemoteAudio();
        }
        if (track.kind === "video") {
          this.removeRemoteVideo();
        }
      });
    },
    async handleRemoteUnute(track) {
      track.on("enabled", () => {
        if(enableLogs) console.log("Remote track enabled");
        if (track.kind === "audio") {
          this.enableRemoteAudio();
        }
        if (track.kind === "video") {
          this.enableRemoteVideo();
        }
      });
    },

    async changeAudio(state) {
      this.remoteAudioMuted = state;
    },
    async changeVideo(state) {
      this.remoteVideoMuted = state;
      setTimeout(() => {
        var v = document.getElementsByTagName("video");
        var a =  document.getElementById("avatar-invitado");
        if (v.length == 2) {
          if (this.remoteVideoMuted) {
            v[1].setAttribute("style", "filter: contrast(0);");
            a.setAttribute("style", "opacity: 1;");
          } else {
            v[1].setAttribute("style", "");
            a.setAttribute("style", "opacity: 0;");
          }
        }
      }, 500);
    },

    async changeLocalVideo() {
      setTimeout(() => {
        var v = document.getElementsByTagName("video");
        var a =  document.getElementById("avatar-propio");
        if (v.length >= 1) {
          if (!this.videoEnabled) {
            v[0].setAttribute("style", "filter: contrast(0);");
            a.setAttribute("style", "opacity: 1;");
          } else {
            v[0].setAttribute("style", "");
            a.setAttribute("style", "opacity: 0;");
          }
        }
      }, 10);
    },

    // TODO: Enable remote audio and video, and remove remote audio and video
    async removeRemoteAudio() {
      this.changeAudio(true);
    },
    async removeRemoteVideo() {
      this.changeVideo(true);
    },

    async enableRemoteAudio() {
      this.changeAudio(false);
    },

    async enableRemoteVideo() {
      this.changeVideo(false);
    },

    async checkVoicePermissions() {
      try {
        this.voiceAllowed = true;
        let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        stream.getTracks().forEach(function (track) {
          track.stop();
        });

        if(enableLogs) console.log("Successfully allowed voice track");
        return true;
      } catch (err) {
        this.voiceAllowed = false;

        //alert("Debes darle permiso de microfono al navegador!");
       if(enableLogs) console.log(err);
       if(enableLogs) console.log("Error allowing voice track");
        return false;
      }
    },
    async checkVideoPermissions() {
      try {
        this.videoAllowed = true;

        let stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks().forEach(function (track) {
          track.stop();
        });

       if(enableLogs) console.log("Successfully allowed video track");
        return true;
      } catch (err) {
        this.videoAllowed = false;

        //alert("Debes darle permiso de camara al navegador!");
       if(enableLogs) console.log(err);
       if(enableLogs) console.log("Error allowing video track");
        return false;
      }
    },
    async enableVoice() {
      await this.$nextTick();
      if (this.localParticipant === null) return;
      this.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable();
      });
      this.voiceEnabled = true;
    },
    async disableVoice() {
      await this.$nextTick();
      if (this.localParticipant === null) return;
      this.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable();
      });
      this.voiceEnabled = false;
    },
    async enableVideo() {
      let canSendVideo = await this.checkVideoPermissions();
      if (!canSendVideo) return;
      this.videoEnabled = true;

      // Set the local tracks
      this.setLocalTracks();
      // Publish your video stream
      if (this.localParticipant === null) return;
      this.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable();
      });
      this.changeLocalVideo();
    },
    async disableVideo() {
      if (this.localParticipant === null) return;
      // Unpublish all your tracks
      this.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable();
      });
      this.videoEnabled = false;
      this.changeLocalVideo();
    },
    // Toggle voice
    async voice() {
      if (this.voiceEnabled == false) {
        if (!(await this.checkVoicePermissions()))
          return alert("Tienes que habilitar el acceso a la camara!");

        await this.enableVoice();
        // Checks if permissions are set
      } else {
        await this.disableVoice();
       if(enableLogs) console.log("Voice disabled");
      }
    },
    // Toggle video
    async camera() {
      if (this.videoEnabled == false) {
        if (!(await this.checkVideoPermissions()))
          return alert("Tienes que habilitar el acceso a la camara!");
        await this.enableVideo();
       if(enableLogs) console.log("Video enabled");
        // Checks if permissions are set
      } else {
        await this.disableVideo();
       if(enableLogs) console.log("Video disabled");
      }
    },

    cronometro() {
      if (this.tiempo == null) {
        this.tiempo = window.setInterval(this.intervalo, 1000);
      }
    },

    intervalo() {
      this.segundos++;
      if (this.segundos == 60) {
        this.segundos = 0;
        this.minutos++;

        if (this.minutos == 60) {
          this.minutos = 0;
        }
      }
    },

    // Conteo descendente
    cronometroMostrar() {
      if (this.tiempoMostrar == null) {
        this.tiempoMostrar = window.setInterval(this.intervaloMostrar, 1000);
      }
    },
    intervaloMostrar() {
      if (this.segundosMostrar > 0 || this.minutosMostrar > 0) {
        if (this.segundosMostrar === 0) {
          if (this.minutosMostrar > 0) {
            this.segundosMostrar = 59;
            this.minutosMostrar--;
          }
        } else {
          this.segundosMostrar--;
        }
      }/* else {
        // Detener el conteo regresivo cuando llegue a 0
        this.detenerCronometroMostrar();
        alert("¡El tiempo ha terminado!");
      }*/
    },
    dosDigitos(num) {
      var res = num.toString();
      if (res.length == 1) {
        res = "0" + res;
      }
      return res;
    },
  },
};
</script>

<style scoped>
.images {
    min-height: calc(100vh - 100px);
    background: rgb(59, 59, 59);
}
.controles {
    height: 100px;
    background: #4b4b4b;
}

button {
    margin: 0 10px;
}
button.btn-voice,
.btn-camera,
.btn-cancel {
    height: 40px;
    width: 40px;
    display: inline-flex;
    margin-top: 28px;
    border: 1px solid #dadce0;
    border-radius: 50%;
    transition-property: all;
    transition-duration: 0.8s;
    background-color: #ffffff;
}
button.btn-voice:hover,
.btn-camera:hover,
.btn-cancel:hover {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 5px 4px rgba(209, 209, 209, 1);
    -moz-box-shadow: 0px 0px 5px 4px rgba(209, 209, 209, 1);
    box-shadow: 0px 0px 5px 4px rgba(209, 209, 209, 1);
}
button.btn-voice i,
.btn-camera i,
.btn-cancel i {
    margin: auto;
    color: #707070;
}

button.btn-voice.active,
.btn-camera.active {
    background-color: #d93025;
}
button.btn-voice.active i,
.btn-camera.active i {
    color: #f5f5f5;
}

.btn-container {
    left: 50%;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    padding: 8px 0;
    position: absolute;
    top: 5%;
    z-index: 99 !important;
}

.icon-mute {
    height: 28px;
    width: 28px;
    background-color: red;
    border-radius: 50%;
    padding: 3px;
}

.icon-mute i {
    width: 5px;
    height: 5px;
    color: #fff;
}
/*contenedor oscuro cuando se desactiva la cam*/
/* .no-media-div {
    width: 100%;
    background-color: black;
    display: flex;
    margin: auto !important;
    position: relative;
    border-radius: 10px !important;
  } */
/*contendor de los videos*/
.container-video {
    width: 50%;
    padding: 0px 15px;
}
/deep/video {
    width: 100%;
    margin: auto !important;
    border-radius: 10px !important;
    max-height: 450px;
    object-fit: cover;
    min-height: 450px;
}
/*div de el video local*/
.local-media-div {
    position: relative;
    margin: auto !important;
}

/**/
.contrainer-icon-video-mute {
    width: 25px;
    height: 25px;
    background: #707070;
    border-radius: 50%;
    position: absolute;
    margin-top: 5px;
    margin-left: 35px;
    z-index: 99;
}
.contrainer-icon-video-mute i {
    margin-top: 5px;
    font-size: 11px;
    margin: auto;

    color: #fff;
}
/**/
.contrainer-icon-microphone-mute {
    width: 25px;
    height: 25px;
    background: #707070;
    border-radius: 50%;
    position: absolute;
    margin: 5px;
    z-index: 99;
}
.contrainer-icon-microphone-mute i {
    margin-top: 5px;
    font-size: 11px;
    margin: auto;
    color: #fff;
}
.remote-media-div {
    position: absolute;
    text-align: center;
}
.contrainer-icon-microphone-mute-remote {
    width: 25px;
    height: 25px;
    background: #707070;
    border-radius: 50%;
    position: absolute;
    margin: 5px;
    z-index: 99;
    margin-left: 5px;
}
.contrainer-icon-microphone-mute-remote i {
    margin-top: 5px;
    font-size: 11px;
    margin: auto;
    color: #fff;
}

.no-media-div .contrainer-icon-video-mute {
    display: none;
}
@media screen and (max-width: 768px) {
    /*contendor de los videos*/
    .container-video {
        width: 90%;
        padding: 0px 15px;
    }
    video {
        max-height: 300px;
        min-height: 300px;
    }
    .content-img-perfil img {
    margin-top: 100px;
    }
}
</style>

<style>
/deep/video {
    width: 100%;
    margin: auto !important;
    border-radius: 10px !important;
    max-height: 500px;
    object-fit: cover;
    min-height: 500px;
}
@media screen and (max-width: 768px) {
    video {
        max-height: 350px !important;
        min-height: 350px !important;
    }
    .content-img-perfil img {
    margin-top: 100px;
    }
}

.time,
.time span {
    font-family: maven-medium;
    color: #fff;
}
.time span {
    font-family: maven-medium;
    color: #fff;
}
.content-img {
    position: relative;
}
.content-img .image{
    display: flex;
    margin-left: auto;
    margin-top: auto;
    margin-top: 20px;
}
.content-img img {

    max-width: 150px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    margin-left: auto;
    margin-right: auto;
}

.content-img-perfil {
    position: relative;
    z-index: 100;
}
.content-img-perfil img {
    position: absolute;
    max-width: 150px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: calc(50% - 60px); */
    margin-top: 180px;
    opacity: 0;
}
</style>
